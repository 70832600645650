.about-me-wrapper{
  background-color: $black;
  p{
    color: $white;
  }
  .skills-container {
    margin-top: 50px;
    h3 {
      margin-top: 30px;
    }
    .skills-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 30px 0;
      i, svg {
        color: $bold-txt;
        font-size: 1.5rem;
      }
      .icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
        width: 95px;
      }
    }
  }
}
