.navigation-bar-wrapper {
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  padding: 10px 10px;
  transform: translateY(-50px);
  z-index: 1;
  &.slide-in{
    animation: slide 0.5s 0.2s ease-in forwards;
  }
  svg {
    font-size: 1.5rem;
  }
  button {
    background: transparent;
    border: none;
    color: $black;
    font-weight: 700;
    cursor: pointer;
  }
  .nav-bar-options-wrapper {
    button {
      margin: 3px 10px;
    }
  }
}

.hide {
  display: none;
}

.show {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 38px;
  left: 0;
  background-color: $white;
  width: 100vw;
}

@media screen and (min-width: 500px) {
  .navigation-bar-wrapper {
    padding: 10px 20px;
    .nav-options-btn {
      display: none;
    }
    button {
      padding: 0 20px;
    }
    .hide {
      display: inline;
    }
  }
  
}

@keyframes slide {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}