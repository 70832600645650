.interests-container {
  background-color: $black;
  padding-bottom: 70px;
  p{
    color: $white;
  }
  .interest-wrapper {
    margin: 50px 0;
    max-width: 450px;
    .interest-body-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
      img {
        height: 110px;
        border-radius: 5px;
        margin: 10px;
      }
      p {
        margin-left: 15px;
      }
    }
    .interest-links-wrapper {
      display: flex;
      justify-content: space-around;
      
      a {
        text-decoration: none;
        text-align: center;
        color: $white;
        border: 1px solid transparent;
        padding: 3px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          border: 1px solid $white;
          border-radius: 5px;
        }
        svg {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .interests-main-flex-wrapper {
    display: flex;
    justify-content: space-between;
    p {
      margin: 15px 30px 30px 30px;
    }
  }
}