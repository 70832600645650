.home-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  .profile-photo {
    border-radius: 50%;
    height: 200px;
    margin-top: 30px;
    opacity: 0;
  }

  .fade {
    animation: fade-in 1s forwards;
  }
  
  .home-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    text-align: center;
    h1 {
      font-size: 2rem;
    }
    h1 span {
      position: relative;
      animation: move-text 0.5s forwards;
      bottom: -1em;
      opacity: 0;
    }

    h2 {
      font-weight: 400;
      color: $black;
      opacity: 0;
    }
    svg {
      margin-top: 30px;
      &.fade {
        animation: bounce 1s 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) infinite forwards;
      }
      height: 40px;
      color: $bold-txt;
      opacity: 0;
      
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }
  
  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}