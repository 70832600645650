@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

$white: #fffefd;
$bold-txt: #4dc1b4;
$black: #242424;
$light-grey: #f1f1f1;

%box-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.26);
}