.projects-container {
  display: flex;
  flex-direction: column;
  .project-wrapper {
    background-color: $light-grey;
    padding: 30px 0;
    margin: 30px 5px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    justify-content: space-between;
    .screenshot-wrapper {
      img {
        width: 300px;
        height: 180px;
        border-radius: 5px;
      }
    }
    .project-text-wrapper {
      padding: 0 10px;
    }
    h4 {
      font-weight: 300;
    }
    .links-wrapper {
      display: flex;
      justify-content: space-around;
      justify-self: flex-end;
      a {
        margin-top: 10px;
        text-decoration: none;
        color: $bold-txt;
        cursor: pointer;
        font-weight: 600;
        padding: 3px 30px;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid $bold-txt;
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .projects-container{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

  }
}