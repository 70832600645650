@import './variables.scss';
@import './components/navbar.scss';
@import './components/home.scss';
@import './components/about.scss';
@import './components/interests.scss';
@import './components/projects.scss';
@import './components/contact.scss';

* {
  margin: 0;
  box-sizing: border-box;
  // font-family: 'Poppins', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

p {
  font-weight: 200;
  margin: 10px 0
}

h1, h2, h3 {
  color: $bold-txt;
  margin: 10px 0;
}

section {
  background-color: $white;
  padding: 50px 5px 15px 5px;
}

.faint {
  color: lighten($black, 30%);
}

@media screen and (min-width: 450px){
  section {
    padding: 50px 10% 15px 10%;
  }
}

