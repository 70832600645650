.contact-container {
  .icon-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 50px 0;
    a {
      margin: 0 30px;
      text-decoration: none;
      color: $bold-txt;
      font-size: 2rem;
    }
  }

}